<h3 class="e11-w-full e11-flex e11-items-center e11-text-skin-primary-accent e11-mb-2">
  {{ 'Please answer the following questions:' | translate }}
  <e11-tooltip [color]="'dark'" tooltipText="{{ 'These are required to apply for this position.' | translate }}">
    <span class="material-icons-outlined md-18 e11-ml-1"> info </span>
  </e11-tooltip>
</h3>
<e11-panel [hasBorder]="false">
  <div *ngIf="!loading && jobDetail; else Loading">
    <!-- Confirmations -->
    <e11-dynamic-form-builder
      #responsesFormBuilder
      *ngIf="fieldGroups"
      [fieldGroups]="fieldGroups"
      [hideSubmitMenu]="true"
      (valueChanges)="onValueChange($event)"
    >
      <ng-template e11Template="authorizationHeader">
        <h5 class="e11-font-primary-demibold e11-mb-4 e11-leading-6">{{ 'Employment Authorization' | translate }}</h5>
      </ng-template>
      <ng-template e11Template="authorizationNote">
        <p class="e11-mb-0">
          {{
            'Can you legally work in the country this job requires you to work in and do you require sponsorship during the time of employment?'
              | translate
          }}
        </p>
      </ng-template>
      <ng-template e11Template="temporaryAuthNote">
        <p class="e11-mb-0">{{ 'If your Employment Authorization is temporary please enter its expiration date.' | translate }}</p>
      </ng-template>
      <ng-template e11Template="authorizationFooter">
        <p class="e11-text-xs e11-pb-4 e11-border-b">
          <span class="e11-font-semibold">NOTE:</span>
          {{
            'Answers here will be saved for future applications. You can manage the countries where you are authorize to work in your account settings.'
              | translate
          }}
        </p>
      </ng-template>
      <ng-template e11Template="hoursConfirmedHeader">
        <h5 *ngIf="jobDetail.travelPercentage || jobDetail.scheduleText?.length" class="e11-font-primary-demibold e11-mb-4">
          {{ 'Job Requirements' | translate }}
        </h5>
      </ng-template>
      <ng-template e11Template="hoursConfirmedNote">
        <div *ngIf="jobDetail.scheduleText?.length">
          <p
            class="e11-mb-0"
            [ngClass]="{
              'e11-text-skin-error':
                responsesFormBuilder.form.controls.hoursConfirmed.invalid && (responsesFormBuilder.form.dirty || responsesFormBuilder.form.touched)
            }"
          >
            {{ 'Can you work the days and/or hours of the job?' | translate }}
          </p>
          <!-- Schedule Details -->
          <div
            class="e11-cursor-pointer e11-relative e11-w-fit"
            (mouseenter)="toggleConfirmationDetails()"
            (mouseleave)="toggleConfirmationDetails()"
          >
            <span class="e11-text-skin-primary-accent">{{ 'Work Schedule' | translate }}</span>
            <span class="confirmations e11-shadow-md" [ngClass]="{ active: showConfirmationDetails }">
              <job-schedule [schedule]="jobDetail?.scheduleText || ''"></job-schedule>
            </span>
          </div>
        </div>
      </ng-template>

      <ng-template e11Template="travelConfirmedNote">
        <p
          *ngIf="jobDetail.travelPercentage"
          class="e11-mb-0"
          [ngClass]="{
            'e11-text-skin-error':
              responsesFormBuilder.form.controls.travelConfirmed.invalid && (responsesFormBuilder.form.dirty || responsesFormBuilder.form.touched)
          }"
        >
          {{ 'Can you meet the travel requirements of' | translate }} {{ jobDetail?.travelPercentage }}%?
        </p>
      </ng-template>
      <ng-template e11Template="experienceLevelHeader">
        <h5 class="e11-font-primary-demibold e11-mb-4">{{ 'Relevant Job Experience' | translate }}</h5>
      </ng-template>
      <ng-template e11Template="experienceLevelNote">
        <p
          class="e11-mb-0"
          [ngClass]="{
            'e11-text-skin-error':
              responsesFormBuilder.form.controls.yearsRelevantExperience.invalid &&
              (responsesFormBuilder.form.dirty || responsesFormBuilder.form.touched)
          }"
        >
          {{ 'How many years of experience do you have related to this job?' | translate }}
        </p>
      </ng-template>
    </e11-dynamic-form-builder>
  </div>

  <ng-template #Loading>
    <e11-loader-dots [size]="'md'" [color]="'e11-bg-skin-primary'"></e11-loader-dots>
  </ng-template>
</e11-panel>
