import { Pipe, PipeTransform } from '@angular/core'
import { ProfileSection } from 'shared-lib'
import { ICandidateResumeVM } from '../models/candidate-resume.vm'
import { checkSectionInResume } from '../util/candidate-resume.util'

@Pipe({
  name: 'sectionInResume',
})
export class SectionInResumePipe implements PipeTransform {
  transform(section: ProfileSection, resume: ICandidateResumeVM): boolean {
    return checkSectionInResume(section, resume)
  }
}
