import { Component, OnInit, ViewChild, inject } from '@angular/core'
import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'
import { IEmployerDetailsVM } from '@candidate/app/models/employer/employer.model'
import { PublicEmployerService } from '@candidate/app/services/employer/employer-public.service'
import * as fromReduce from '@candidate/app/store/reducers'
import { IRegistrationResult } from '@engineering11/registration-web'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { isNotNil, valueOf } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, filter, map, take } from 'rxjs/operators'
import { OnRegistration, SessionStorageService, TokenStorage } from 'shared-lib'

@Component({
  selector: 'registration-consumer-quick',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegistrationRegisterComponent implements OnInit {
  @ViewChild('modalTerms') modalTerms!: E11ModalComponent
  appConfig = inject(AppConfigService)

  logoUrl?: string | null
  defaultLogoUrl = this.appConfig.config.brand.logoImageDarkUrl

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private employerService: PublicEmployerService,
    private store: Store<fromReduce.user.State>,
    private logger: E11Logger,
    private tokenStorage: TokenStorage
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(map(valueOf('referrerId')), filter(isNotNil), take(1)).subscribe(referrerId => {
      this.sessionStorage.setItem('referrerId', referrerId)
    })
    this.route.queryParams.pipe(map(valueOf('customerKey')), take(1)).subscribe(customerKey => {
      if (!customerKey) {
        this.logoUrl = this.defaultLogoUrl
      } else {
        this.employerService
          .get(customerKey)
          .pipe(
            take(1),
            catchError(err => {
              return of({ logoImageURL: this.defaultLogoUrl } as IEmployerDetailsVM)
            })
          )
          .subscribe(customer => {
            this.logoUrl = customer?.logoImageURL || this.defaultLogoUrl
          })
      }
    })
  }

  async onSubmit(result: IRegistrationResult) {
    // This is here to avoid a race condition?
    this.tokenStorage.setAccessToken(result.authToken)
    this.tokenStorage.setItem('user', JSON.stringify(result.appUser))
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    let redirectPath = ''
    if (primary.segments.length > 1) {
      redirectPath = decodeURIComponent(primary.segments[primary.segments.length - 1].path)
    }

    if (result) {
      this.logger.log(`Redirect path: ${redirectPath}`)
      this.store.dispatch(new OnRegistration({ appUserId: result.appUser.id, queryParams: {} }))
      this.router.navigate([redirectPath], { queryParamsHandling: 'merge' })
    }
  }

  termsClicked() {
    this.modalTerms.open()
  }

  navigateToLogin() {
    this.router.navigate(['/auth/login'], { queryParamsHandling: 'merge' })
  }
}
