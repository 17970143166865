import { Injectable, TemplateRef } from '@angular/core'
import { E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'

@Injectable({
  providedIn: 'root',
})
export class ApplicationSubmissionModalService {
  private modalContent: TemplateRef<any> | undefined

  constructor(private globalModalService: E11ModalGlobalService) {}

  open() {
    if (!this.modalContent) {
      return
    }

    this.globalModalService.openModal({
      body: this.modalContent,
      bodyHeight: 'auto',
      showFooter: false,
      title: 'Application Submission',
      width: 'lg',
    })
  }

  set modalBody(content: TemplateRef<any>) {
    this.modalContent = content
  }
}
