<ng-container *ngrxLet="features$ as features">
  <div class="e11-mb-5 e11-hidden lg:e11-block app-nav-left e11-w-full">
    <div
      #navContainer
      class="e11-bg-skin-app-bg-dark e11-text-white/70 e11-overflow-hidden e11-rounded nav-prototype"
      style="top: {{ navTop + 'px' }}"
    >
      <div
        [routerLink]="routes.PROFILES"
        class="top e11-flex e11-items-center e11-h-12 e11-px-4 e11-font-primary-demibold hover:e11-bg-skin-primary e11-cursor-pointer e11-border-b e11-border-b-skin-grey/30"
      >
        <span class="material-icons e11-mr-2 md-18"> home </span>
        {{ 'Home' | translate }}
      </div>
      <div
        class="middle e11-relative e11-overscroll-contain e11-overflow-y-scroll e11-scrollbar-thumb-blue e11-scrollbar-thumb-rounded e11-scrollbar-track-blue-lighter e11-scrollbar-w-2 e11-scrolling-touch"
      >
        <div class="bg e11-absolute"></div>

        <!-- My Network -->
        <ul *ngIf="features?.network">
          <li
            (click)="toggleMyNetworkLinks()"
            [ngClass]="{ 'e11-bg-skin-primary/70 e11-border-b-transparent': showMyNetworkLinks }"
            class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-cursor-pointer hover:e11-bg-skin-primary hover:e11-text-white"
          >
            <span class="e11-font-medium e11-text-md e11-relative"
              >{{ 'My Network' | translate }}
              <e11-badge
                *ngIf="incomingConnectionCount$ | async"
                [color]="'error'"
                [containerClassOverrides]="''"
                [limit]="UNREAD_LIMIT"
                [posAdjustX]="'-4px'"
                [posAdjustY]="'-11px'"
                [size]="'xs'"
                [value]="(incomingConnectionCount$ | async) ?? 0"
              ></e11-badge
            ></span>
            <span [ngClass]="{ 'nav-icon-active': showMyNetworkLinks }" class="material-icons-outlined e11-text-skin-extra-light nav-icon">
              expand_more
            </span>
          </li>
          <div [ngClass]="{ 'nav-messages-container-active': showMyNetworkLinks }" class="nav-messages-container e11-px-2">
            <ul class="e11-my-2">
              <li
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLink]="[routes.CONNECTIONS]"
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/30 e11-rounded-md"
              >
                Cnections
              </li>
              <li
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLink]="[routes.INVITATIONS]"
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/30 e11-rounded-md"
              >
                {{ 'Invitations' | translate }}
              </li>
            </ul>
          </div>
        </ul>

        <!-- Messages -->
        <ul *ngIf="features?.messaging">
          <li
            (click)="toggleDirectMessages()"
            [ngClass]="{ 'e11-bg-skin-primary/70 e11-border-b-transparent': showDirectMessages, 'e11-border-b-skin-grey/30': !showDirectMessages }"
            class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-border-b e11-border-t e11-border-t-skin-grey/30 hover:e11-bg-skin-primary e11-cursor-pointer hover:e11-text-white"
          >
            <span class="e11-font-medium e11-text-md e11-relative"
              >{{ 'Messages' | translate }}
              <e11-badge
                *ngIf="unreadConversationCount$ | async"
                [color]="'error'"
                [containerClassOverrides]="''"
                [limit]="UNREAD_LIMIT"
                [posAdjustX]="'-4px'"
                [posAdjustY]="'-11px'"
                [size]="'xs'"
                [value]="(unreadConversationCount$ | async) ?? 0"
              ></e11-badge>
            </span>
            <span [ngClass]="{ 'nav-icon-active': showDirectMessages }" class="material-icons-outlined e11-text-skin-extra-light nav-icon">
              expand_more
            </span>
          </li>

          <div
            [ngClass]="{ 'nav-messages-container-active e11-border-b e11-border-b-skin-grey/30': showDirectMessages }"
            class="nav-messages-container e11-px-2"
          >
            <div class="e11-w-full e11-mt-2 e11-mb-4 e11-rounded-lg e11-bg-skin-primary-accent/20 e11-pt-4">
              <p class="e11-font-medium e11-text-md e11-mb-0 e11-pl-2">{{ 'Job Related' | translate }}</p>
              <nav-item-conversation-group [features]="features"></nav-item-conversation-group>
            </div>

            <p class="e11-font-medium e11-text-md e11-mb-2 e11-pl-2">{{ 'Social' | translate }}</p>
            <nav-item-conversation [features]="features"></nav-item-conversation>

            <p class="e11-font-medium e11-text-md e11-mb-2 e11-pl-2">{{ 'Outreach' | translate }}</p>
            <nav-item-conversation [features]="features" [conversationType]="ConversationTypes.CandidatePoolDM"></nav-item-conversation>
          </div>
        </ul>

        <!-- Communities -->
        <ul *ngIf="features?.communities" class="e11-mb-6">
          <li
            (click)="toggleCommunities()"
            [ngClass]="{ 'e11-bg-skin-primary/70 e11-border-b-transparent': showCommunities, 'e11-border-b-skin-grey/30': !showCommunities }"
            class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-border-b e11-cursor-pointer hover:e11-bg-skin-primary hover:e11-text-white"
          >
            <span class="e11-font-medium e11-text-md e11-relative"
              >{{ 'Communities' | translate }}
              <e11-badge
                *ngIf="unreadCommunityChannelCount$ | async"
                [color]="'error'"
                [containerClassOverrides]="''"
                [limit]="UNREAD_LIMIT"
                [posAdjustX]="'-4px'"
                [posAdjustY]="'-11px'"
                [size]="'xs'"
                [value]="(unreadCommunityChannelCount$ | async) ?? 0"
              ></e11-badge
            ></span>
            <span [ngClass]="{ 'nav-icon-active': showCommunities }" class="material-icons-outlined e11-text-skin-extra-light nav-icon">
              expand_more
            </span>
          </li>
          <div [ngClass]="{ 'nav-messages-container-active': showCommunities }" class="nav-messages-container e11-px-2 e11-pt-2">
            <sdk-community-nav></sdk-community-nav>
          </div>
        </ul>
      </div>
      <!-- / middle -->

      <!-- bottom -->
      <!-- IF infoTips feature is on it will override the 'My Account' link at the bottom -->
      <!-- <div
        *ngIf="!features?.informationalContent"
        [routerLink]="[routes.ACCOUNT]"
        class="bottom e11-flex e11-items-center e11-h-12 e11-px-4 e11-bg-skin-app-bg-dark e11-font-primary-demibold hover:e11-bg-skin-black e11-cursor-pointer e11-border-t e11-border-t-skin-grey/30"
      >
        <ng-container *ngIf="currentUser$ | async as currentUser">
          <e11-avatar [hasBorder]="false" [hasRing]="false" [imageUrl]="currentUser.id | toIdenticon" [size]="'sm'"></e11-avatar>
        </ng-container>
        <span class="e11-ml-2 e11-text-sm">
          {{ 'My Account' | translate }}
        </span>
      </div> -->

      <div
        *ngIf="features?.informationalContent"
        [routerLink]="['../info/home']"
        class="bottom e11-flex e11-items-center e11-h-12 e11-px-4 e11-bg-skin-app-bg-dark e11-font-primary-demibold hover:e11-bg-skin-black e11-cursor-pointer e11-border-t e11-border-t-skin-grey/30"
      >
        <span *ngIf="features?.informationalContent" class="material-icons-outlined e11-cursor-pointer e11-text-skin-primary-accent">
          tips_and_updates
        </span>
        <span class="e11-ml-2 e11-text-sm">
          {{ 'Info and Tips' | translate }}
        </span>
      </div>
    </div>
  </div>
</ng-container>
