import { ErrorHandlingConfig } from 'shared-lib'

export const ERROR_HANDLING: ErrorHandlingConfig = {
  USER_NAME_MIN_LENGTH: 8,
  USER_NAME_MAX_LENGTH: 50,

  PASSWORD_MIN_LENGTH: 8,
  PASSWORD_MAX_LENGTH: 24,

  EMAIL_MIN_LENGTH: 7,
  EMAIL_MAX_LENGTH: 100,

  FIRST_NAME_MIN_LENGTH: 1,
  FIRST_NAME_MAX_LENGTH: 100,

  LAST_NAME_MIN_LENGTH: 1,
  LAST_NAME_MAX_LENGTH: 100,

  COMPANY_NAME_MIN_LENGTH: 5,
  COMPANY_NAME_MAX_LENGTH: 100,

  PHONE_MIN_LENGTH: 10,
  PHONE_MAX_LENGTH: 20,

  ADDRESS_MIN_LENGTH: 4,
  ADDRESS_MAX_LENGTH: 100,

  STATE_REQUIRED_LENGTH: 2,
  ZIP_MIN_LENGTH: 5,
  ZIP_MAX_LENGTH: 12,
  COUNTRY_REQUIRED_LENGTH: 2,
}

export const PREMIUM_ROLE = 'Premium'

// Videos
export const MAX_VIDEOS_FIRST_IMPRESSION = 3
export const MAX_VIDEOS_FIRST_IMPRESSION_PREMIUM = 100
export const MAX_DURATION_FIRST_IMPRESSION = 60 // seconds

// Identicons
export const IDENTICON = Object.freeze({
  ROOT_PATH: 'assets/identicon/',
  TAIL: '_identity.svg',
})

export const RESUME_SUMMARY_MAX_LENGTH = 600
export const RESUME_COMPANY_NAME_MAX_LENGTH = 100
export const RESUME_SCHOOL_NAME_MAX_LENGTH = 100
export const RESUME_TITLE_MAX_LENGTH = 50
