import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { AbstractControl, Validators } from '@angular/forms'
import { EmploymentAuthorizationStore } from '@candidate/app/modules/account/stores/employment-authorization.store'
import {
  E11DynamicFormBuilderComponent,
  e11FieldInputNumber,
  e11FieldInputRadio,
  e11FieldInputSelect,
  e11FieldInputText,
  e11FormFor,
  E11FormForType,
} from '@engineering11/ui-lib/e11-form-builder'
import { E11Logger } from '@engineering11/web-api-error'
import { formSelectOptionsFutureYears, formSelectOptionsMonths } from '@engineering11/web-utilities'
import { firstValueFrom } from 'rxjs'
import { EmploymentAuthorizationType, ICandidateJobVM, ICandidateResponses } from 'shared-lib'

interface EmploymentAuthorizationForm {
  visaSponsorshipRequired: string
  legalWorkAuthorizationType: string
  legalWorkAuthorizationCountryCode: string
  temporaryAuthorizationEndMonth: string
  temporaryAuthorizationEndYear: string
  hoursConfirmed: boolean
  travelConfirmed: boolean
  yearsRelevantExperience: string
  monthsRelevantExperience: string
}

@Component({
  selector: 'candidate-question-responses',
  templateUrl: './candidate-question-responses.component.html',
  styleUrls: ['./candidate-question-responses.component.scss'],
})
export class CandidateQuestionResponsesComponent implements OnInit, OnDestroy {
  @ViewChild('responsesFormBuilder', { static: false }) responsesFormBuilder: E11DynamicFormBuilderComponent<EmploymentAuthorizationForm> | undefined
  @Input() currentResponses?: ICandidateResponses

  @Input() jobDetail: ICandidateJobVM | undefined

  @Input() loading = false

  @Output() newResponses = new EventEmitter<ICandidateResponses>()

  showConfirmationDetails: boolean = false

  // Must remain at latest to prevent bug where candidate submits their application without the latest responses
  currentCandidateResponses?: ICandidateResponses

  fieldGroups!: E11FormForType<EmploymentAuthorizationForm>

  constructor(private logger: E11Logger, private employmentAuthorizationStore: EmploymentAuthorizationStore) {}

  ngOnInit() {
    if (!this.jobDetail) return
    this.initFormGroup(this.jobDetail!, this.currentResponses)
  }

  ngOnDestroy(): void {}

  get f() {
    return this.responsesFormBuilder?.form.controls
  }

  get isFormValid() {
    this.responsesFormBuilder?.onSubmitClick()
    return this.responsesFormBuilder?.form.valid
  }

  get responsesFormGroup() {
    return this.responsesFormBuilder?.form
  }

  get isScheduleAvailable() {
    return this.jobDetail?.scheduleText && this.jobDetail.scheduleText.length
  }

  private async initFormGroup(jobDetails: ICandidateJobVM, currentResponses?: ICandidateResponses) {
    this.logger.log('initting form group with ', currentResponses)

    const maxYearsExperience = 123
    const maxMonths = 11

    const authorizationForCountry = await firstValueFrom(
      this.employmentAuthorizationStore.countryAuthorization$(jobDetails.locationAddress?.countryCode ?? 'US')
    )

    const tempWorkExpDate = authorizationForCountry?.tempWorkAuthorizationExpiration ?? currentResponses?.tempWorkAuthorizationExpiration ?? null
    const sponsorshipDefault = currentResponses?.visaSponsorshipRequired ? (currentResponses.visaSponsorshipRequired ? 'yes' : 'no') : 'no'
    const sponsorship = authorizationForCountry?.visaSponsorshipRequired
      ? authorizationForCountry!.visaSponsorshipRequired
        ? 'yes'
        : 'no'
      : sponsorshipDefault
    const tempEndMonth = tempWorkExpDate ? (tempWorkExpDate.month > 10 ? `${tempWorkExpDate.month}` : `0${tempWorkExpDate.month}`) : null
    const tempEndYear = tempWorkExpDate ? tempWorkExpDate.year + '' : null

    this.fieldGroups = e11FormFor<EmploymentAuthorizationForm>()([
      {
        fields: [],
        layouts: [
          {
            fields: [
              e11FieldInputSelect(
                'legalWorkAuthorizationType',
                `Country: ${jobDetails.locationAddress?.countryCode ?? 'U.S'}`,
                [
                  { value: 'permanent', name: 'Yes' },
                  { value: 'temporary', name: 'Yes - (Temporary Authorization)' },
                  { value: 'none', name: 'No' },
                ],
                authorizationForCountry?.legalWorkAuthorizationType ?? currentResponses?.legalWorkAuthorizationType ?? 'permanent',
                {
                  validatorOrOpts: [Validators.required],
                }
              ),
              e11FieldInputSelect(
                'visaSponsorshipRequired',
                'Sponsorship',
                [
                  { value: 'yes', name: 'Yes' },
                  { value: 'no', name: 'No' },
                ],
                sponsorship,
                { validatorOrOpts: [Validators.required] }
              ),
            ],
            containerClass: 'e11-flex e11-gap-2 e11-flex-wrap lg:e11-flex-nowrap lg:e11-justify-end e11-items-center',
            fieldsClass: 'e11-w-full lg:e11-w-1/2',
          },
        ],
        containerClass: 'e11-flex lg:e11-justify-between e11-justify-start e11-items-center e11-flex-wrap e11-gap-4 lg:e11-flex-nowrap',
        templateSpec: [
          { templateKey: 'authorizationNote', templatePosition: 'left' },
          {
            templateKey: 'authorizationHeader',
            templatePosition: 'top',
          },
        ],
      },
      {
        fields: [],
        layouts: [
          {
            fields: [
              e11FieldInputSelect('temporaryAuthorizationEndMonth', 'Month', [...formSelectOptionsMonths], tempEndMonth, {
                validatorOrOpts: [Validators.required],
                isHidden: false,
                isDisabled: true,
              }),
              e11FieldInputSelect('temporaryAuthorizationEndYear', 'Year', [...formSelectOptionsFutureYears], tempEndYear, {
                validatorOrOpts: [Validators.required],
                isHidden: false,
                isDisabled: true,
              }),
            ],
            containerClass: 'e11-flex e11-gap-2 e11-justify-end e11-items-center',
            fieldsClass: 'e11-w-[50%] lg:e11-w-[43%]',
          },
        ],
        containerClass: 'e11-flex e11-justify-start e11-items-center e11-flex-wrap lg:e11-justify-between e11-gap-4 lg:e11-flex-nowrap',
        templateSpec: [
          { templateKey: 'temporaryAuthNote', templatePosition: 'left' },
          {
            templateKey: 'temporaryNote',
            templatePosition: 'top',
          },
          {
            templateKey: 'authorizationFooter',
            templatePosition: 'bottom',
          },
        ],
      },
      {
        fields: [
          e11FieldInputRadio(
            'hoursConfirmed',
            '',
            [
              {
                name: 'Yes',
                value: true,
                radioProps: { color: 'primary-accent' },
              },
              {
                name: 'No',
                value: false,
                radioProps: { color: 'primary-accent' },
              },
            ],
            currentResponses?.hoursConfirmed ?? true,
            {
              isHidden: !this.isScheduleAvailable,
              validatorOrOpts: [this.isScheduleAvailable ? Validators.required : Validators.nullValidator],
            }
          ),
        ],
        containerClass: 'e11-flex e11-justify-start e11-items-center e11-flex-wrap lg:e11-justify-between e11-gap-4 lg:e11-flex-nowrap',
        fieldsClass: 'e11-w-[40%] lg:e11-w-1/5 e11-flex e11-justify-end',
        templateSpec: [
          { templateKey: 'hoursConfirmedNote', templatePosition: 'left' },
          {
            templateKey: 'hoursConfirmedHeader',
            templatePosition: 'top',
          },
        ],
      },
      {
        fields: [
          e11FieldInputRadio(
            'travelConfirmed',
            '',
            [
              {
                name: 'Yes',
                value: true,
                radioProps: { color: 'primary-accent' },
              },
              {
                name: 'No',
                value: false,
                radioProps: { color: 'primary-accent' },
              },
            ],
            currentResponses?.travelConfirmed ?? true,
            {
              isHidden: !jobDetails.travelPercentage,
              validatorOrOpts: [jobDetails.travelPercentage ? Validators.required : Validators.nullValidator],
            }
          ),
        ],
        containerClass: 'e11-flex e11-justify-start e11-items-center e11-flex-wrap lg:e11-justify-between e11-gap-4 lg:e11-flex-nowrap',
        fieldsClass: 'e11-w-[40%] lg:e11-w-1/5 e11-flex e11-justify-end',
        templateSpec: { templateKey: 'travelConfirmedNote', templatePosition: 'left' },
      },
      {
        fields: [],
        layouts: [
          {
            fields: [
              e11FieldInputNumber('yearsRelevantExperience', 'Years', `${currentResponses?.yearsRelevantExperience ?? 0}`, {
                validatorOrOpts: [Validators.required, Validators.min(0), Validators.max(maxYearsExperience)],
              }),
              e11FieldInputNumber('monthsRelevantExperience', 'Months', `${currentResponses?.monthsRelevantExperience ?? 0}`, {
                validatorOrOpts: [Validators.required, Validators.min(0), Validators.max(maxMonths)],
              }),
            ],
            containerClass: 'e11-flex e11-gap-2 e11-justify-end e11-items-center',
            fieldsClass: 'e11-w-[50%] lg:e11-w-[36%]',
          },
        ],
        containerClass: 'e11-flex e11-justify-start e11-items-center e11-flex-wrap lg:e11-justify-between e11-gap-4 lg:e11-flex-nowrap',
        templateSpec: [
          { templateKey: 'experienceLevelHeader', templatePosition: 'top' },
          { templateKey: 'experienceLevelNote', templatePosition: 'left' },
        ],
      },
      {
        fields: [
          e11FieldInputText('legalWorkAuthorizationCountryCode', '', jobDetails.locationAddress?.countryCode ?? '', {
            isHidden: true,
          }),
        ],
      },
    ])
  }

  toggleConfirmationDetails() {
    // This could be handled in the template but putting here incase we need to expand on the functionality later
    this.showConfirmationDetails = !this.showConfirmationDetails
  }

  onValueChange(value: EmploymentAuthorizationForm) {
    const controls = this.responsesFormBuilder!.form.controls

    if (value.legalWorkAuthorizationType === 'temporary' && controls.temporaryAuthorizationEndMonth.disabled) {
      this.enableControl(controls.temporaryAuthorizationEndMonth)
      this.enableControl(controls.temporaryAuthorizationEndYear)
    }

    if (value.legalWorkAuthorizationType !== 'temporary' && controls.temporaryAuthorizationEndMonth.enabled) {
      this.disableControl(controls.temporaryAuthorizationEndMonth)
      this.disableControl(controls.temporaryAuthorizationEndYear)
    }

    this.currentCandidateResponses = formChangesToResponses(value)
  }

  private enableControl(control: AbstractControl) {
    control.enable()
    control.updateValueAndValidity()
  }

  private disableControl(control: AbstractControl) {
    control.disable()
    control.reset()
    control.updateValueAndValidity()
  }
}

function formChangesToResponses(changes: EmploymentAuthorizationForm): ICandidateResponses {
  const hasWorkAuthorization = changes.legalWorkAuthorizationType !== 'none'
  return {
    legalWorkAuthorizationCountryCode: changes.legalWorkAuthorizationCountryCode,
    legalWorkAuthorization: hasWorkAuthorization,
    legalWorkAuthorizationType: changes.legalWorkAuthorizationType as EmploymentAuthorizationType,
    visaSponsorshipRequired: changes.visaSponsorshipRequired === 'yes',
    tempWorkAuthorizationExpiration:
      changes.legalWorkAuthorizationType === 'temporary'
        ? {
            month: parseInt(changes.temporaryAuthorizationEndMonth),
            year: parseInt(changes.temporaryAuthorizationEndYear),
          }
        : undefined,
    hoursConfirmed: changes.hoursConfirmed,
    travelConfirmed: changes.travelConfirmed,
    monthsRelevantExperience: parseInt(changes.monthsRelevantExperience || '0') ?? 0,
    yearsRelevantExperience: parseInt(changes.yearsRelevantExperience || '0') ?? 0,
  }
}
