import { ProfileSection } from 'shared-lib'
import { ICandidateResumeVM } from '../models/candidate-resume.vm'
import { isArray } from '@engineering11/utility'

export function checkSectionInResume(section: ProfileSection, resume: ICandidateResumeVM): boolean {
  if (!resume[section]) return false
  const currSection = resume[section]
  if (isArray(currSection) && currSection.length === 0) return false
  return true
}
