import { environment } from '@candidate/environments/environment'
import * as Rollbar from 'rollbar'

const rollbarConfig: Rollbar.Configuration = {
  enabled: environment.env !== 'dev', // Don't emit when developing on qa
  accessToken: '346f7aa091b0466381e715a7324927ea',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.env,
    server: {
      root: 'webpack:///./',
    },
    client: {
      javascript: {
        source_map_enabled: true, // true by default

        // -- Add this into your configuration ---
        code_version: 'e5dc1c25220b4943659eb94207bfe19222ed119c',
        // ---------------------------------------

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig)
}
